<template>
  <div class="report-filter-info column gap-s">
    <div class="column gap-xs">
      <h3>{{ title }}</h3>

      <span v-if="date" class="report-filter-info__date">
        {{ date }}
      </span>
    </div>

    <div v-if="filters.length" class="column gap-xxs">
      <InfoText
        class="report-filter-info__filter-item"
        v-for="item of filters"
        :key="item.id"
        join="; "
        :label="item.label"
        :text="item.text"
      />
    </div>
  </div>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText'

export default {
  name: 'ReportFilterInfo',
  components: { InfoText },
  props: {
    title: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: false,
      default: ''
    },
    filters: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
