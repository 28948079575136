import { createIdRandom } from '@/utils/create'
import { formatDWire } from '@/utils/format/number'

export function mapReportWireConsumptionTableData (report) {
  return report.map(item => ({
    ...item,
    parameter: item.material,
    ...createItemFields(),
    d_wires: item.d_wires.map(mapDWires)
  }))
}

function mapDWires (item) {
  if (item.outer_reclamations) {
    return {
      ...item,
      parameter: formatDWire(item.d_wire),
      ...createItemFields(),
      outer_reclamations: item.outer_reclamations.map(mapReclamations)
    }
  } else if (item.orders) {
    return {
      ...item,
      parameter: formatDWire(item.d_wire),
      ...createItemFields(),
      orders: item.orders.map(mapOrders)
    }
  } else {
    return {
      ...item,
      parameter: formatDWire(item.d_wire),
      ...createItemFields(),
      inner_reclamations: item.rows.map(mapInnerReclamations)
    }
  }
}
function mapReclamations (item) {
  return {
    ...item,
    ...createIdRandom(),
    parameter: [{
      account_number: item.account_number,
      id: item.outer_reclamation_id
    }]
  }
}
function mapOrders (item) {
  return {
    ...item,
    ...createIdRandom(),
    parameter: [{
      account_number: item.account_number,
      id: item.order_id
    }]
  }
}
function mapInnerReclamations (item) {
  return {
    ...item,
    ...createIdRandom(),
    parameter: [{
      consignment_id: item.consignment_id,
      consignment_text: `${item.consignment_sort} (№${item.order_account_number} / №${item.position_sort})`
    }]
  }
}
function createItemFields () {
  return {
    ...createIdRandom(),
    isNotLink: true,
    isFillRow: true
  }
}
