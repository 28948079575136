import { CHART_DATASETS, CHART_DATASETS_STYLES } from '@/const/chart'

export function getChartDatasets (list, options) {
  const dataset = []

  const count = options.length
  const keys = []

  for (const item of options) {
    dataset.push({ data: [], label: item.label })
    keys.push(item.byKey)
  }

  for (const item of list) {
    for (let i = 0; i < count; i++) {
      dataset[i].data.push(item[keys[i]])
    }
  }

  for (let i = 0; i < count; i++) {
    dataset[i] = {
      ...CHART_DATASETS_STYLES,
      ...CHART_DATASETS[i],
      ...dataset[i]
    }
  }

  return dataset
}

export const visibleDataLabel = ctx => ctx.dataset.data[ctx.dataIndex] > 0
