export const CHART_DATASETS_STYLES = {
  pointRadius: 4,
  borderWidth: 1,
  barThickness: 80
}

export const CHART_DATASETS = [
  {
    backgroundColor: 'rgba(102,153,255,0.8)',
    borderColor: 'rgba(102,153,255,0.8)'
  },
  {
    backgroundColor: '#3E6ECA',
    borderColor: '#3E6ECA'
  }
]
